import React from 'react';
import Image from 'react-image-enlarger';
import { createGlobalStyle } from 'styled-components';

type ZoomImageProps = {
    src: string;
    style?: Record<string, any>;
    className?: string;
};

const GlobalImageStyles = createGlobalStyle`
    .EnlargedImage__enlarged-container {
        z-index: 1001!important;
    }
    .EnlargedImage__clone {
        padding: 12px;
    }
`;

const ZoomImage = (props: ZoomImageProps): React.ReactElement => {
    const [zoomed, setZoomed] = React.useState(false);

    const firstOpen = React.useRef(false);

    const handleClickOpen = (): void => {
        // workaround for bug with "react-image-enlarger"
        // at first open library create clone of image and can not get in time
        // open large image. we emulate double usage of zoomed image at first interactive
        if (!firstOpen.current) {
            firstOpen.current = true;

            setZoomed(true);

            setTimeout(() => setZoomed(false), 0);
            setTimeout(() => setZoomed(true), 60);

            return;
        }

        setZoomed(true);
    };

    const handleClickClose = (): void => setZoomed(false);

    return (
        <>
            <GlobalImageStyles />

            <Image
                zoomed={zoomed}
                onClick={handleClickOpen}
                onRequestClose={handleClickClose}
                {...props}
            />
        </>
    );
};

export { ZoomImage };
