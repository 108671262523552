import loadable from '@loadable/component';
import {
    AssignmentTurnedIn,
    Book,
    Dashboard,
    DateRange,
    Group,
    Laptop,
    LocalLibrary,
    PieChart,
    Videocam,
    FolderOpen,
    Settings,
} from '@material-ui/icons';

import { BaseLayout, EmptyLayout, ErrorLayout, ExternalLayout } from '@modules/layout/templates';
import { UserGrantCategoryToken, UserRole } from '@modules/types/graphql';

import type { RedirectProps, RouteComponentProps } from '@reach/router';
import type { LoadableComponent } from '@loadable/component';

export enum RouteMenuLocationEnum {
    sidebar = 'sidebar',
}

export enum RouteMenuSectionEnum {
    lapDataOriented = 'lapDataOriented',
    regionalCenterDataOriented = 'regionalCenterDataOriented',
}

export type OriginRoute = {
    name: string;
    title: string;
    path: string;
    private: boolean;
    LayoutComponent: React.FunctionComponent<any>;
    componentPath?: string;
    renderForPath?: Partial<Record<UserRole, string>>;
    redirectTo?: string;
    withBackIcon?: boolean;
    default?: boolean;
    menuTitle?: string;
    categoryGrant?: UserGrantCategoryToken;
    categoryGrantFor?: Partial<Record<UserRole, UserGrantCategoryToken>>;
    roles?: UserRole[];
    menuLocations?: RouteMenuLocationEnum[];
    menuSections?: RouteMenuSectionEnum[];
    menuIcon?: React.FunctionComponent<any>;
    children?: React.ReactNode;
};

export type Route = Omit<OriginRoute, 'renderForPath' | 'componentPath'> & {
    renderFor?: Partial<Record<UserRole, LoadableComponent<any>>>;
    Component?: LoadableComponent<any>;
};

export type OriginRoutes = Record<string, OriginRoute>;
export type ConfigRoutes = Record<string, Route>;

export type RedirectRoute = RouteComponentProps<RedirectProps<{}>> & {
    name: string;
};

const originRoutes: OriginRoutes = {
    index: {
        name: 'index',
        title: 'Index',
        path: '/',
        private: true,
        componentPath: '@pages',
        LayoutComponent: EmptyLayout,
    },

    summary: {
        name: 'summary.index',
        title: 'common:menu.summary',
        path: '/summary',
        private: true,
        categoryGrant: UserGrantCategoryToken.summaryResult,
        menuLocations: [RouteMenuLocationEnum.sidebar],
        menuSections: [RouteMenuSectionEnum.regionalCenterDataOriented],
        menuIcon: AssignmentTurnedIn,
        componentPath: '@pages/summary',
        LayoutComponent: BaseLayout,
    },
    summarySchools: {
        name: 'summary.schools.index',
        title: 'common:menu.schools',
        path: '/summary/schools',
        private: true,
        withBackIcon: true,
        categoryGrant: UserGrantCategoryToken.summaryResultSchool,
        componentPath: '@pages/summary/schools',
        LayoutComponent: BaseLayout,
    },
    summarySchoolsEditPage: {
        name: 'summary.schools.edit',
        title: 'common:menu.edit',
        path: '/summary/schools/:id/edit',
        private: true,
        withBackIcon: true,
        categoryGrant: UserGrantCategoryToken.summaryResultSchool,
        componentPath: '@pages/summary/schools/edit',
        LayoutComponent: BaseLayout,
    },
    summaryStudents: {
        name: 'summary.students.index',
        title: 'common:menu.students',
        path: '/summary/students',
        private: true,
        withBackIcon: true,
        categoryGrant: UserGrantCategoryToken.summaryResult,
        componentPath: '@pages/summary/students',
        LayoutComponent: BaseLayout,
    },
    summaryStudentsEditPage: {
        name: 'summary.students.edit',
        title: 'common:menu.edit',
        path: '/summary/students/:id/edit',
        private: true,
        withBackIcon: true,
        categoryGrant: UserGrantCategoryToken.summaryResult,
        componentPath: '@pages/summary/students/edit',
        LayoutComponent: BaseLayout,
    },

    dashboard: {
        name: 'dashboard.index',
        title: 'common:menu.dashboard',
        path: '/dashboard',
        private: true,
        categoryGrant: UserGrantCategoryToken.odb,
        menuLocations: [RouteMenuLocationEnum.sidebar],
        menuSections: [RouteMenuSectionEnum.lapDataOriented],
        menuIcon: Dashboard,
        componentPath: '@pages/dashboard',
        LayoutComponent: BaseLayout,
    },

    students: {
        name: 'students.index',
        title: 'common:menu.students',
        path: '/students',
        private: true,
        categoryGrant: UserGrantCategoryToken.student,
        menuLocations: [RouteMenuLocationEnum.sidebar],
        menuSections: [RouteMenuSectionEnum.lapDataOriented],
        menuIcon: Group,
        componentPath: '@pages/students',
        LayoutComponent: BaseLayout,
    },
    studentsSinglePage: {
        name: 'students.single',
        title: '',
        path: '/students/:id',
        private: true,
        withBackIcon: true,
        categoryGrant: UserGrantCategoryToken.student,
        componentPath: '@pages/students/single',
        LayoutComponent: BaseLayout,
    },
    studentsEditPage: {
        name: 'students.edit',
        title: 'common:menu.edit',
        path: '/students/:id/edit',
        private: true,
        withBackIcon: true,
        categoryGrant: UserGrantCategoryToken.student,
        componentPath: '@pages/students/edit',
        LayoutComponent: BaseLayout,
    },

    employees: {
        name: 'employees.index',
        title: 'common:menu.employees',
        path: '/employees',
        private: true,
        categoryGrant: UserGrantCategoryToken.employee,
        menuLocations: [RouteMenuLocationEnum.sidebar],
        menuSections: [RouteMenuSectionEnum.regionalCenterDataOriented],
        menuIcon: LocalLibrary,
        componentPath: '@pages/employees',
        LayoutComponent: BaseLayout,
    },
    employeesEditPage: {
        name: 'employees.edit',
        title: 'common:menu.edit',
        path: '/employees/:id/edit',
        private: true,
        withBackIcon: true,
        categoryGrant: UserGrantCategoryToken.employee,
        componentPath: '@pages/employees/edit',
        LayoutComponent: BaseLayout,
    },

    onlineLearning: {
        name: 'courses.index',
        title: 'common:menu.online_learning',
        path: '/online-learning',
        private: true,
        categoryGrant: UserGrantCategoryToken.ol,
        menuLocations: [RouteMenuLocationEnum.sidebar],
        menuSections: [RouteMenuSectionEnum.regionalCenterDataOriented],
        menuIcon: Laptop,
        componentPath: '@pages/online-learning',
        renderForPath: {
            [UserRole.student]: '@pages/online-learning/student',
        },
        LayoutComponent: BaseLayout,
    },
    subjects: {
        name: 'subjects.index',
        title: '',
        path: '/online-learning/:courseId/subjects',
        private: true,
        withBackIcon: true,
        categoryGrant: UserGrantCategoryToken.olSubject,
        categoryGrantFor: {
            [UserRole.student]: UserGrantCategoryToken.olTesting,
        },
        componentPath: '@pages/online-learning/subjects',
        renderForPath: {
            [UserRole.student]: '@pages/online-learning/student/subjects',
        },
        LayoutComponent: BaseLayout,
    },
    subject: {
        name: 'subject.show',
        title: '',
        path: '/online-learning/:courseId/subjects/:subjectId/show',
        private: true,
        withBackIcon: true,
        categoryGrant: UserGrantCategoryToken.olSubject,
        categoryGrantFor: {
            [UserRole.student]: UserGrantCategoryToken.olTesting,
        },
        componentPath: '@pages/online-learning/subjects/show',
        renderForPath: {
            [UserRole.student]: '@pages/online-learning/student/show',
        },
        LayoutComponent: BaseLayout,
    },
    subjectEditPage: {
        name: 'subject.edit',
        title: '',
        path: '/online-learning/:courseId/subjects/:subjectId/edit',
        private: true,
        withBackIcon: true,
        categoryGrant: UserGrantCategoryToken.olSubject,
        componentPath: '@pages/online-learning/subjects/edit',
        LayoutComponent: BaseLayout,
    },
    lecture: {
        name: 'lecture.index',
        title: '',
        path: '/online-learning/:courseId/subjects/:subjectId/lecture/:id',
        private: true,
        withBackIcon: true,
        categoryGrant: UserGrantCategoryToken.olSubject,
        categoryGrantFor: {
            [UserRole.student]: UserGrantCategoryToken.olTesting,
        },
        componentPath: '@pages/online-learning/lectures/show',
        LayoutComponent: BaseLayout,
    },
    lectureEditPage: {
        name: 'lecture.edit',
        title: '',
        path: '/online-learning/:courseId/subjects/:subjectId/lecture/:id/edit',
        private: true,
        withBackIcon: true,
        categoryGrant: UserGrantCategoryToken.olSubject,
        componentPath: '@pages/online-learning/lectures/edit',
        LayoutComponent: BaseLayout,
    },

    eljur: {
        name: 'eljur.auth',
        title: '',
        path: '/eljur/:regionalCenterId/auth',
        private: false,
        componentPath: '@pages/eljur/auth',
        LayoutComponent: ExternalLayout,
    },
    eljurLecture: {
        name: 'eljur.lecture',
        title: '',
        path: '/eljur/:regionalCenterId/online-learning/:courseId/subjects/:subjectId/lecture/:id',
        // Надо будет потом придумать что-то, если историю с внешними страницами будут развивать
        // Нужно научится делать приватные страницы, но при это они не должны быть связаны с основным сервисом
        // Возможно нужно научиться привязываться к currentUser и смотреть на эту сущность для определения private
        // TODO: здесь должно быть private: true
        private: false,
        componentPath: '@pages/eljur/lecture',
        LayoutComponent: ExternalLayout,
    },
    eljurTesting: {
        name: 'eljur.lecture',
        title: 'common:menu.testing',
        path: '/eljur/:regionalCenterId/online-learning/testing',
        // TODO: здесь должно быть private: true, если появится третья похожая страница - нужно будет делать
        private: false,
        componentPath: '@pages/eljur/testing',
        LayoutComponent: ExternalLayout,
    },

    testing: {
        name: 'testing.index',
        title: 'common:menu.testing',
        path: '/online-learning/testing',
        private: true,
        withBackIcon: true,
        categoryGrant: UserGrantCategoryToken.olTesting,
        componentPath: '@pages/online-learning/testing',
        LayoutComponent: BaseLayout,
    },

    schools: {
        name: 'schools.index',
        title: 'common:menu.schools',
        menuTitle: 'common:menu.short_schools',
        path: '/schools',
        private: true,
        categoryGrant: UserGrantCategoryToken.school,
        menuLocations: [RouteMenuLocationEnum.sidebar],
        menuSections: [RouteMenuSectionEnum.lapDataOriented],
        menuIcon: Book,
        componentPath: '@pages/schools',
        LayoutComponent: BaseLayout,
    },
    schoolsEditPage: {
        name: 'schools.edit',
        title: 'common:menu.edit',
        path: '/schools/:id/edit',
        private: true,
        withBackIcon: true,
        categoryGrant: UserGrantCategoryToken.school,
        componentPath: '@pages/schools/edit',
        LayoutComponent: BaseLayout,
    },

    stats: {
        name: 'stats.index',
        title: 'common:menu.stats',
        path: '/stats',
        private: true,
        categoryGrant: UserGrantCategoryToken.stat,
        menuLocations: [RouteMenuLocationEnum.sidebar],
        menuSections: [RouteMenuSectionEnum.lapDataOriented],
        menuIcon: PieChart,
        componentPath: '@pages/stats',
        LayoutComponent: BaseLayout,
    },

    live: {
        name: 'live.index',
        title: 'common:menu.live',
        path: '/live',
        private: true,
        categoryGrant: UserGrantCategoryToken.liveBroadcast,
        menuLocations: [RouteMenuLocationEnum.sidebar],
        menuSections: [RouteMenuSectionEnum.regionalCenterDataOriented],
        menuIcon: Videocam,
        componentPath: '@pages/live',
        LayoutComponent: BaseLayout,
    },

    handbook: {
        name: 'handbook.index',
        title: 'common:menu.handbook',
        path: '/handbook',
        private: true,
        categoryGrant: UserGrantCategoryToken.handbook,
        menuLocations: [RouteMenuLocationEnum.sidebar],
        menuSections: [RouteMenuSectionEnum.regionalCenterDataOriented],
        menuIcon: FolderOpen,
        componentPath: '@pages/handbook',
        LayoutComponent: BaseLayout,
    },
    handbookSchoolsEditPage: {
        name: 'handbook.schools.edit',
        title: 'common:menu.edit',
        path: '/handbook/schools/:id/edit',
        private: true,
        withBackIcon: true,
        categoryGrant: UserGrantCategoryToken.handbookSchool,
        componentPath: '@pages/handbook/schools/edit',
        LayoutComponent: BaseLayout,
    },
    handbookStudentsEditPage: {
        name: 'students.edit',
        title: 'common:menu.edit',
        path: '/handbook/students/:id/edit',
        private: true,
        withBackIcon: true,
        categoryGrant: UserGrantCategoryToken.handbookStudent,
        componentPath: '@pages/handbook/students/edit',
        LayoutComponent: BaseLayout,
    },

    laps: {
        name: 'laps.index',
        title: 'common:menu.laps',
        path: '/laps',
        private: true,
        categoryGrant: UserGrantCategoryToken.lap,
        menuLocations: [RouteMenuLocationEnum.sidebar],
        menuSections: [RouteMenuSectionEnum.regionalCenterDataOriented],
        menuIcon: DateRange,
        componentPath: '@pages/laps',
        LayoutComponent: BaseLayout,
    },

    settings: {
        name: 'settings.index',
        title: 'common:menu.settings',
        path: '/settings',
        private: true,
        roles: [UserRole.superAdmin, UserRole.admin],
        menuLocations: [RouteMenuLocationEnum.sidebar],
        menuSections: [RouteMenuSectionEnum.regionalCenterDataOriented],
        menuIcon: Settings,
        componentPath: '@pages/settings',
        LayoutComponent: BaseLayout,
    },

    profile: {
        name: 'profile.index',
        title: 'common:menu.profile',
        path: '/profile',
        private: true,
        componentPath: '@pages/profile',
        LayoutComponent: BaseLayout,
    },

    error: {
        name: 'error',
        title: 'Error Page',
        path: '/error',
        private: false,
        componentPath: '@pages/error',
        LayoutComponent: ErrorLayout,
    },
};

const routes = Object.entries(originRoutes).reduce((carry, [key, route]) => {
    let asyncComponent: Route['Component'] = undefined;
    let renderFor: Route['renderFor'] = undefined;

    if (route.componentPath) {
        const path = route.componentPath.replace('@', '');

        asyncComponent = loadable(() => import(/* webpackPrefetch: true */ `../${path}`));
    }

    if (route.renderForPath) {
        renderFor = Object.entries(route.renderForPath).reduce((carry, [role, componentPath]) => {
            if (componentPath) {
                const path = componentPath.replace('@', '');
                const asyncComponent = loadable(
                    () => import(/* webpackPrefetch: true */ `../${path}`),
                );

                return { ...carry, [role]: asyncComponent };
            }

            return carry;
        }, {});
    }

    return {
        ...carry,
        [key]: { ...route, renderFor, Component: asyncComponent },
    };
}, {} as ConfigRoutes);

const redirects: RedirectRoute[] = [
    {
        name: 'default',
        from: '/:lang',
        to: routes.index.path,
        default: true,
    },
    // {
    //     name: 'online-learning.subjects',
    //     from: '/:lang/online-learning/subjects',
    //     to: '/:lang' + routes.onlineLearning.path,
    // },
];

export { routes, redirects };
